.FurconCardAvatar {
    border-radius: 4px;
    width: 36px;
    height: 36px;
    background: transparent no-repeat;
    background-clip: padding-box;
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    overflow: hidden;
}

/* 
Fix for Safari issue with multiple columns and overflow. COME ON APPLE
*/
@media screen and (min-width: 780px) {
    _::-webkit-full-page-media, _:future, :root .FurconCardAvatar {

        overflow: visible;
      
    }
}



.FurconCardAvatar > img {
    display: block;
    width: 100%;
    height: 100%;
}

.FurconCard {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: white;
    padding: 10px;
    border-radius: 5px;
    background-color: #4d5866;
    background-color: #303a46;
    background-color: #303a46;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%234c5e74' fill-opacity='0.3' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    filter: drop-shadow(2px 2px 2px #000);
    transition: filter 500ms ;
}

.FurconCard:hover {
    filter: drop-shadow(0px 0px 2px rgb(127, 149, 167));
}

.FurconCardContent {
    display:flex;
    flex: 1 1 auto;
    align-items: center;
    gap: 10px;
}

.FurconCardDisplayName {
    display:block;
    font-size: 16px;
}

.FurconCardDisplayNameProper {
    width:200px;
    display: block; 
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: 500;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.FurconCardDisplayNameAccount {
    width: auto;
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: 400;
    color: #e9e9e9;
    width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.FurconCardArrowContainer {
    display: flex;
    margin-right: 5px;
    transition: transform 2s east-out 100ms;
}

a, p, div, footer, header, h1, h2, h3 {
}