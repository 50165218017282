a {
  color:rgb(154, 243, 255)
}

a:hover {
  color:rgb(199, 249, 255)
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ToMastodon {
  color:rgb(243, 255, 191);
  text-decoration: none;
}

.ToMastodon:hover {
  color:rgb(154, 243, 255)
}

.Title {
  margin: 0;
  font-size: 4em;
font-family: bebas-neue-pro-expanded, sans-serif;
}

.SubTitle {
  margin-top: 0;
    font-size: .8em;
    
font-family: bebas-neue-pro-expanded, sans-serif;
    font-weight: 400;
}

.AccountsTitle {
  margin: 1%;
}

.AccountsBox {
  width: 100%;
  box-shadow: inset 0 0 10px #080808;
  background-color: rgb(14 14 14 / 60%);
}

.AccountsBorder{
  width: 95vw;
  max-width: 1000px;
  margin: auto;
}

.furconMessage {
  font-size: 16px;
}

@media screen and (max-width: 43rem) {
  .Info {
    padding: 10px;
  }

  .Title {
    font-size: 3.5em;
  }

  .furconMessage {
    padding: 20px;
    margin: 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

p {
  font-size: 24px;
}

footer {
  position: absolute;
  bottom: 0;
  font-size: 12px;
  display:flex;
}

.App-header {
	/* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite; */
  /* background-color: #303a46; */
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.box {
  width: 95vw;
  max-width: 1000px;
  background-color:rgba(31, 31, 31, 0.842);
  -webkit-border-radius: 1vh;
  -moz-border-radius: 1vh;
  border-radius: 1vh;
  -webkit-box-shadow: 0px 5px 13px -6px #000000; 
  box-shadow: 0px 5px 13px -8px #000000;
}

.arrow {
	border: solid rgb(255, 255, 255);
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 5px;
  }
  
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}