.accountList{
    list-style-type: none;
    column-count: 3;
    -webkit-column-count: 3;
    column-width: 300px;
    padding: 5px;
}

.accountListItem {
    padding: 10px 5px;
    break-inside: avoid;
}

.loading {
    display:inline-block;
    font-family: monospace;
    /* clip-path: inset(0 3ch 0 0); */
    /* animation: load 1s steps(4) infinite; */
}

.loadingDot1 {
    opacity: 0%;
    animation: showDot 1s infinite;
}

.loadingDot2 {
    opacity: 0%;
    animation: showDot 1s infinite;
    animation-delay: .2s;
}

.loadingDot3 {
    opacity: 0%;
    animation: showDot 1s infinite;
    animation-delay: .4s;
}

@keyframes showDot {
    0% {opacity: 0%;}
    25% {opacity: 0%;}
    50% {opacity: 100%;}
    75% {opacity: 100%;}
    100% {opacity: 0%;}
}

@keyframes load {
    to {
      clip-path: inset(0 -1ch 0 0)
    }
  }